import React, { useState } from 'react';
import './Slider.css';
import KeyboardArrowLeftOutlinedIcon from '@mui/icons-material/KeyboardArrowLeftOutlined';
import KeyboardArrowRightOutlinedIcon from '@mui/icons-material/KeyboardArrowRightOutlined';

const Slider = ({ images }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [startX, setStartX] = useState(null);

  const goToSlide = (index) => {
    setCurrentIndex(index);
  };

  const goToNextSlide = () => {
    const newIndex = currentIndex === images.length - 1 ? 0 : currentIndex + 1;
    setCurrentIndex(newIndex);
  };

  const goToPrevSlide = () => {
    const newIndex = currentIndex === 0 ? images.length - 1 : currentIndex - 1;
    setCurrentIndex(newIndex);
  };

  const handleTouchStart = (event) => {
    setStartX(event.nativeEvent.touches[0].clientX); // Record initial touch X
  };

  const handleTouchMove = (event) => {
    const currentX = event.nativeEvent.touches[0].clientX;
    const deltaX = currentX - startX; // Calculate swipe distance

    if (Math.abs(deltaX) > 50) {
      // Threshold for swipe detection
      if (deltaX > 0) {
        // Left swipe (positive deltaX)
        goToPrevSlide();
      } else {
        // Right swipe (negative deltaX)
        goToNextSlide();
      }
    }

    setStartX(null); // Reset on touch move end
  };

  return (
    <div className='slider-container'>
      <div className='navbar'>
        {images.map((imageUrl, index) => (
<>
  {
            imageUrl.endsWith(".mp4")?<>
            <video
            
            src={imageUrl}
            className={`nav-img ${index === currentIndex ? 'active' : ''}`}
            onClick={() => goToSlide(index)}
            key={index}
            >

            </video>
            </>:<>
            <img
            key={index}
            src={imageUrl}
            style={{}}
            alt={`Thumbnail ${index + 1}`}
            className={`nav-img ${index === currentIndex ? 'active' : ''}`}
            onClick={() => goToSlide(index)}
          />
            </>
          }
         
</>
        
        ))}
      </div>
      <div className='slider1'>
        <div
          className='slides-container'
          style={{ transform: `translateX(-${currentIndex * 100}%)` }}
          onTouchStart={handleTouchStart}
          onTouchMove={handleTouchMove}
        >
          {images.map((imageUrl, index) => (
            <div
              key={index}
              className={`slide1 ${index === currentIndex ? 'active' : ''}`}
            >
              {
                imageUrl.endsWith(".mp4")?<>
                
                <video
                controls
                style={{
                  width: '100%',
                  aspectRatio: 1 / 1,
                }}
                src={imageUrl}
                className='slide-img'

                >

                </video>
                
                </>:<>
                
                <img
                style={{
                  width: '100%',
                  aspectRatio: 1 / 1,
                }}
                src={imageUrl}
                alt={`Slide ${index + 1}`}
                className='slide-img'
              />
                </>
              }
             
            </div>
          ))}
        </div>
        <button className='prev-btn' onClick={goToPrevSlide}>
          <KeyboardArrowLeftOutlinedIcon
            sx={{
              fontWeight: 'bold',
              fontSize: 24,
              color: 'black',
              opacity: 1,
            }}
          />
        </button>
        <button className='next-btn' onClick={goToNextSlide}>
          <KeyboardArrowRightOutlinedIcon
            sx={{
              fontWeight: 'bold',
              fontSize: 24,
              color: 'black',
              opacity: 1,
            }}
          />
        </button>
      </div>
    </div>
  );
};

export default Slider;
